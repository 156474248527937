body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Ubuntu', sans-serif;;
}

/* .MuiTableRow-root td:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius:10px;
  }
  
  .MuiTableRow-root td:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
 */
